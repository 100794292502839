import React from "react";
import "./TC.scss";
function TC() {
  return (
    <div className="tc">
      <div className="tc__heading">
        <p>T&C</p>
      </div>
      <div className="tc__title">
        <p style={{ color: "#454753", margin: "40px 0px" }}>
          General Terms and Conditions of Business (T&C)
        </p>
      </div>
      <div className="tc__title">
        <p>(a) General</p>
      </div>
      <div className="tc__list">
        <ul>
          <li>
            TripGeni GmbH, Berlin (referred to hereinafter as TripGeni)
            carries out its services solely on the basis of these T&C. These T&C
            govern the contractual relationship between TripGeni and the
            client. This will also apply to all future services if this is not
            explicitly agreed again to in the T&C.
          </li>
          <li>
            Deviations from these conditions of business are only valid if
            TripGeni confirms them to its clients in writing. Otherwise,
            conflicting or deviating conditions of the client in its conditions
            of business or purchase do not apply.
          </li>
          <li>
            In addition to these T&C, special provisions may be used if external
            service providers are used for any particular service.
          </li>
        </ul>
      </div>
      <div className="tc__title">
        <p>(b) Quotes, Prices, Payment Conditions</p>
      </div>
      <div className="tc__list">
        <ul>
          <li>
            All quotes are subject to change and are not binding. All prices are
            net and exclude the applicable statutory value added tax.
          </li>
          <li>
            Commissions and irregular services will be invoiced immediately but
            at least once a month. Continuous services are invoiced in advance
            monthly or every quarter. Unless otherwise agreed, payment will be
            by direct debit.
          </li>
          <li>
            Project agreements will be invoiced after completion. TripGeni is
            entitled to invoice partial deliveries from project agreements on a
            monthly basis.
          </li>
          <li>
            Invoices are generated electronically and signed digitally. A
            service charge of EUR 2.50 will be charged for individual invoices
            in paper form.
          </li>
          <li>
            If a direct debit cannot be carried out due to a lack of funds or
            for another reason for which the client is responsible, the client
            will be obliged to reimburse any resulting bank charges. If a debit
            entry is reversed, i.e. if a debit entry is returned unpaid,
            TripGeni will charge the client 15 euros for the fees charged by
            the receiving bank and the additional administrative work and
            expense.
          </li>
          <li>
            The Client is also liable for all possible transfer risks,
            including, but not limited to, technical, tax, fines, losses
            incurred by third parties, etc.
          </li>
          <li>
            TripGeni reserves the right to charge accruing reminder and
            processing fees. TripGeni also reserves the right to assign
            verified debts with clients to third parties.
          </li>
          <li>
            Should the client fail to adhere to payment deadlines set by
            TripGeni, interest after the due date of 4% p.a. over the
            applicable discount rate of the European Central Bank will be
            charged. The client is at liberty to prove that the amount of damage
            due to arrears is less. In cases of arrears of payment, TripGeni
            will be entitled to block the client’s access to the service. The
            client’s claims for payment or reimbursement will be offset against
            TripGeni invoice claims every month or 14 days by special
            agreement.
          </li>
        </ul>
      </div>
      <div className="tc__title">
        <p>(c) Registration</p>
      </div>
      <div className="tc__list">
        <ul>
          <li>
            {" "}
            Clients have to provide true and valid information. Client cannot
            use the name of another person, the name that is offensive, caddish,
            or infringes someone’s intellectual property rights, or otherwise
            violates these Terms. If Client is a legal entity, his
            representative must have the valid legal authority to represent that
            entity.
          </li>
          <li>
            It’ll be Client’s responsibility to notify TripGeni immediately
            about any unauthorized access of use of his/her account or password
            or any other breach of security.
          </li>
        </ul>
      </div>
      <div className="tc__title">
        <p>(d) Duration and Termination of the Agreement</p>
      </div>
      <div className="tc__list">
        <ul>
          <li>
            The contractual relationship begins on the day the agreement is
            first performed and will run for an indefinite period subject to any
            term stipulated in the agreement. It may be terminated with a notice
            period of 3 month to the end of the month. If a minimum term is
            agreed on, the agreement may not be terminated until this period has
            expired at the earliest.
          </li>
          <li>
            Notice of termination must be in writing. In cases where there is
            reasonable cause to suspect abuse, TripGeni may block services
            without prior warning. The client is forbidden from using services
            provided in this agreement in any illegal fashion. It will be liable
            for the consequences of any such illegal use.
          </li>
          <li>
            After the agreement has ended, TripGeni will be entitled
            unconditionally to delete all the client’s data.
          </li>
        </ul>
      </div>
      <div className="tc__title">
        <p>(e) Use of External Services</p>
      </div>
      <div className="tc__list">
        <ul>
          <li>
            Where external services are used, TripGeni will act only as a
            broker between the client and each service provider. At its option,
            TripGeni reserves the right to temporarily or permanently alter,
            interrupt or stop individual or all services at any time, regardless
            of whether it has warned the client or not.
          </li>
          <li>
            TripGeni accepts no liability for and gives no guarantee of the
            proper function of external services, e.g. online booking systems
            and/or their interfaces.
          </li>
        </ul>
      </div>
      <div className="tc__title">
        <p>(f) Content</p>
      </div>
      <div className="tc__list">
        <ul>
          <li>
            The client will not publish content illegally on TripGeni servers.
            The client bears sole responsibility for the right to publish this
            content.
          </li>
          <li>
            The client may not breach legislative bans, public morals or violate
            the rights of third parties pursuant to the law relating to the use
            of a name, copyright or other industrial property rights etc. with
            its form, content or pursued purpose and releases TripGeni from
            all legal claims and costs resulting from the use of external
            materials.
          </li>
          <li>
            TripGeni does not – as a matter of routine – examine or check
            content, but reserves the right to block access to data if third
            parties assert claims to refrain or if the client is not the legal
            owner of published documents or electronic files.
          </li>
        </ul>
      </div>
      <div className="tc__title">
        <p>(g) Data Protection, Operational Readiness</p>
      </div>
      <div className="tc__list">
        <ul>
          <li>
            We may use the information in ways which are provided for the
            Clients under the Legal Notice / Data Protection.
          </li>
          <li>
            If data (i.e. text, images, files) are transmitted to TripGeni in
            any form, the client will make security copies. This is in the
            clients own interest, since, in spite of the fact that data are
            backed up regularly on TripGeni’ servers, it is not technically
            possible to exclude the risk of data-loss with any degree of
            certainty.
          </li>
          <li>
            Emails are to be retrieved and backed up regularly by the client.
            TripGeni will not delete the client’s emails but the risk of
            data-loss due to technical interruptions can never be excluded.
          </li>
          <li>
            TripGeni will endeavour to ensure that its services are available
            all the time but for technical reasons cannot guarantee 100%
            operational availability.
          </li>
          <li>
            Pursuant to Section 33 of the German Data Protection Act, TripGeni
            advises that personal data will be stored and, where appropriate,
            may be passed on to associated cooperation partners, agents and
            service providers to the extent necessary in order to fulfil the
            agreement. If the client wants entries in booking portals, internet
            search programmes or trade directories, the corresponding data will
            not be deemed confidential and may be published freely by TripGeni
            in the internet, thereby being made accessible to third parties.
          </li>
        </ul>
      </div>
      <div className="tc__title">
        <p>(i) Limitation of liability </p>
      </div>
      <div className="tc__list">
        <ul>
          <li>
            Unless stated in the Terms, we are not liable to Client or anyone
            else for any loss of use, data, goodwill, incomes or profits,
            whatsoever, and any special, incidental, indirect, consequential, or
            punitive damages whatsoever, regardless of cause (even if we have
            been advised of the possibility of the loss or damages). That
            includes losses and damages which are result of loss of use, data,
            or profits, whether or not foreseeable; based on any theory of
            liability, including breach of contract or warranty, negligence or
            other similar actions; or arising from any other claim arising out
            of or in connection with your use of or access to the Service.
          </li>
          <li>
            We are also not responsible for all dues, taxes and fees of Client,
            which will may be a result of Client’s usage of applications
            according to law of Client’s residence or/and Client’s national law
            or law of any other country.
          </li>
        </ul>
      </div>
      <div className="tc__title">
        <p>(j) Liability, Compensation for Damage</p>
      </div>
      <div className="tc__list">
        <ul>
          <li>
            All liability is excluded if it is not due to the grossly negligent
            or wilful breach of contract by TripGeni directly, any of its
            legal representatives or agents. The value of any such liability
            will be limited to twelve times TripGeni’ average monthly
            commission for its services (less fees of third parties).
          </li>
          <li>
            This also applies to all damage claims, regardless of reason or
            amount.
          </li>
        </ul>
      </div>
      <div className="tc__title">
        <p>(k) Intellectual property </p>
      </div>
      <div className="tc__list">
        <ul>
          <li>
            All content included as part of the service, such as text, graphics,
            logos, images, as well as the compilation thereof, and any software
            used on the service, is our property or its suppliers and protected
            by copyright and other laws that protect intellectual property and
            proprietary rights. Client agrees to observe and abide by all
            copyright and other proprietary notices or other restrictions
            contained in any such content and will not make any changes thereto.
          </li>
          <li>
            Client shall not modify, publish, transmit, reverse engineer,
            participate in the transfer or sale, create derivative works, or in
            any way exploit any of the content, in whole or in part, found on
            the Service.
          </li>
          <li>
            Client agrees that you do not acquire any ownership rights in any
            protected content. We do not grant Client with any licenses, express
            or implied, to the our intellectual property or our licensors except
            as expressly authorized by these Terms.
          </li>
        </ul>
      </div>
      <div className="tc__title">
        <p>(l) Final Provisions</p>
      </div>
      <div className="tc__list">
        <ul>
          <li>
            All alterations, corrections, ancillary arrangements, notices of
            termination or the partial or entire cancellation of the contractual
            relationship must be in writing, as must the alteration or
            cancellation of the requirement for the use of written
            notifications.
          </li>
          <li>
            TripGeni reserves the right to amend or add to the T&C in the
            future. You will be advised separately of any additions or
            alterations to these T&C.
          </li>
          <li>
            The exclusive place of fulfilment and jurisdiction for all disputes
            arising from this contractual relationship is Berlin. This agreement
            is subject to the laws of the Federal Republic of Germany.
          </li>
          <li>
            Should a provision of this agreement be invalid or become invalid or
            should this agreement contain an omission, then the legal effect of
            the other provision shall not thereby be affected. Instead of the
            invalid provision a valid provision is deemed to have been agreed
            upon which comes closest to what the parties intended; the same
            applies in the case of an omission.
          </li>
        </ul>
      </div>
      <div className="tc__title">
        <p>(m) Contact us</p>
      </div>
      <div className="tc__list">
        <ul>
          <li>
            If you need more information about Terms or have any proposal to
            improve them, you are welcome to contact us at:
            info@tripgeni.com
          </li>
        </ul>
      </div>
      
    </div>
  );
}

export default TC;

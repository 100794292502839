export const OTA = [
  {
    key: "1",
    image: "./Images/ConnectionsOTA/1.png",
    name: "Agoda.com",
    region: "Worldwide",
    audience: "All Types",
  },
  {
    key: "2",
    image: "./Images/ConnectionsOTA/2.png",
    name: "Airbnb",
    region: "Worldwide",
    audience: "All Types",
  },
  {
    key: "3",
    image: "./Images/ConnectionsOTA/3.png",
    name: "Atrapalo.com",
    region: "Worldwide, Spanish-speaking countries",
    audience: "All Types",
  },
  {
    key: "4",
    image: "./Images/ConnectionsOTA/4.png",
    name: "Bed and Breakfast",
    region: "Europe",
    audience: "Apartments, Private accomadations",
  },
  {
    key: "5",
    image: "./Images/ConnectionsOTA/5.png",
    name: "Booking.com",
    region: "Worldwide",
    audience: "All Types",
  },
  {
    key: "6",
    image: "./Images/ConnectionsOTA/6.png",
    name: "Corprate Rates Club",
    region: "Germany",
    audience: "Corporate bookings",
  },
  ,
  {
    key: "7",
    image: "./Images/ConnectionsOTA/7.png",
    name: "Despegar.com",
    region: "Worldwide, Spanish-speaking countries",
    audience: "All Types",
  },
  {
    key: "8",
    image: "./Images/ConnectionsOTA/10.png",
    name: "ebookers.com",
    region: "Worldwide",
    audience: "All Types",
  },
  {
    key: "9",
    image: "./Images/ConnectionsOTA/11.png",
    name: "eDreams ODIGEO Connect",
    region: "Worldwide",
    audience: "All Types",
  },
  {
    key: "10",
    image: "./Images/ConnectionsOTA/12.png",
    name: "ehotels.com",
    region: "Germany",
    audience: "Alltypes",
  },
  {
    key: "11",
    image: "./Images/ConnectionsOTA/13.png",
    name: "Expedia.com",
    region: "Worldwide",
    audience: "All Types",
  },
  {
    key: "12",
    image: "./Images/ConnectionsOTA/14.png",
    name: "Freetime Company",
    region: "Germany, Holland, Benelux",
    audience: "Short Trips",
  },
  {
    key: "13",
    image: "./Images/ConnectionsOTA/15.png",
    name: "Gloveler.com",
    region: "Germany",
    audience: "Short Trips, Private accomadation",
  },
  {
    key: "14",
    image: "./Images/ConnectionsOTA/16.png",
    name: "Goibibo.com",
    region: "India",
    audience: "All Types",
  },
  {
    key: "15",
    image: "./Images/ConnectionsOTA/17.png",
    name: "Hostelbookers.com",
    region: "Woldwide",
    audience: "",
  },
  {
    key: "16",
    image: "./Images/ConnectionsOTA/18.png",
    name: "Hostelclub.com",
    region: "Europe",
    audience: "Hostels",
  },
  {
    key: "17",
    image: "./Images/ConnectionsOTA/19.png",
    name: "Hostelworld.com",
    region: "Worldwide",
    audience: "Hostels",
  },
  {
    key: "18",
    image: "./Images/ConnectionsOTA/20.png",
    name: "Hoteiliers.com",
    region: "Europe, Holland",
    audience: "All Types",
  },
  {
    key: "19",
    image: "./Images/ConnectionsOTA/21.png",
    name: "Hotels.com",
    region: "Worldwide",
    audience: "All Types",
  },
  {
    key: "20",
    image: "./Images/ConnectionsOTA/22.png",
    name: "HotelSpecials.de",
    region: "Holland",
    audience: "All Types",
  },
  {
    key: "21",
    image: "./Images/ConnectionsOTA/23.png",
    name: "HotelTonight.com",
    region: "Worldwide",
    audience: "All Types",
  },
  {
    key: "22",
    image: "./Images/ConnectionsOTA/24.png",
    name: "HRS Group",
    region: "Germany, Europe",
    audience: "All Types",
  },
  {
    key: "23",
    image: "./Images/ConnectionsOTA/25.png",
    name: "Initalia.it",
    region: "Europe, Italien",
    audience: "All Types",
  },
  {
    key: "24",
    image: "./Images/ConnectionsOTA/38.png",
    name: "kurz-mal-weg.de",
    region: "Germany",
    audience: "Kurzreisen",
  },
  {
    key: "25",
    image: "./Images/ConnectionsOTA/26.png",
    name: "kurzurlaub.de",
    region: "Germany, Benelux, Austria",
    audience: "Short Trips",
  },
  {
    key: "26",
    image: "./Images/ConnectionsOTA/27.png",
    name: "Lastminute.com",
    region: "Worldwide",
    audience: "All Types",
  },
  {
    key: "27",
    image: "./Images/ConnectionsOTA/28.png",
    name: "LateRooms.com",
    region: "Worldwide",
    audience: "All Types",
  },
  {
    key: "28",
    image: "./Images/ConnectionsOTA/29.png",
    name: "MakeMyTrip.com",
    region: "India",
    audience: "All Types",
  },
  {
    key: "29",
    image: "./Images/ConnectionsOTA/30.png",
    name: "Orbitz.com",
    region: "Worldwide",
    audience: "All Types",
  },
  {
    key: "30",
    image: "./Images/ConnectionsOTA/31.png",
    name: "Ostrovok.ru",
    region: "Worldwide,Russia",
    audience: "All Types",
  },
  {
    key: "31",
    image: "./Images/ConnectionsOTA/32.png",
    name: "Schmetterling.de",
    region: "Germany",
    audience: "All Types",
  },
  {
    key: "32",
    image: "./Images/ConnectionsOTA/33.png",
    name: "SuperBreak.com",
    region: "Great Britain",
    audience: "All Types",
  },
  {
    key: "33",
    image: "./Images/ConnectionsOTA/34.png",
    name: "SWISStours",
    region: "Worldwide, Switzerland",
    audience: "All Types",
  },
  {
    key: "34",
    image: "./Images/ConnectionsOTA/35.png",
    name: "switzerlandtravelcentre.com",
    region: "Europe, Switzerland",
    audience: "All Types",
  },
  {
    key: "35",
    image: "./Images/ConnectionsOTA/36.png",
    name: "Tourini.de",
    region: "Germany",
    audience: "All Types",
  },
  {
    key: "36",
    image: "./Images/ConnectionsOTA/37.png",
    name: "TravelRepublic.co.uk",
    region: "Great Britain",
    audience: "All Types",
  },
];

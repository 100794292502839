export const PMS = [
  {
    key: "1",
    image: "./Images/ConnectionsPMS/1.png",
    name: "@-ROOMS of @-Systems",
    region: "Germany",
    certification: "Standard",
  },
  {
    key: "2",
    image: "./Images/ConnectionsPMS/2.png",
    name: "ASA HOTEL from ASA OHG",
    region: "Italy",
    certification: "Basic",
  },
  {
    key: "3",
    image: "./Images/ConnectionsPMS/3.png",
    name: "Bonit Software",
    region: "Germany,Austria",
    certification: "Premium",
  },
  {
    key: "4",
    image: "./Images/ConnectionsPMS/4.png",
    name: "bookatonce",
    region: "Germany",
    certification: "Premium",
  },
  {
    key: "5",
    image: "./Images/ConnectionsPMS/5.png",
    name: "Check in Pro",
    region: "England",
    certification: "Standard",
  },
  {
    key: "6",
    image: "./Images/ConnectionsPMS/6.png",
    name: "Citadel Hotel Software",
    region: "Germany",
    certification: "Premium",
  },
  {
    key: "7",
    image: "./Images/ConnectionsPMS/7.png",
    name: "Cogwave Software Technologies",
    region: "India",
    certification: "Basic",
  },
  {
    key: "8",
    image: "./Images/ConnectionsPMS/8.png",
    name: "CuxDuHier",
    region: "Germany",
    certification: "Premium",
  },
  {
    key: "9",
    image: "./Images/ConnectionsPMS/9.png",
    name: "Dashy Travel",
    region: "Worldwide, Spanish-speaking Countries",
    certification: "Premium",
  },
  {
    key: "10",
    image: "./Images/ConnectionsPMS/10.png",
    name: "Fidelity OMS / MAiS Information Systems Inc",
    region: "Slovenia",
    certification: "Standard",
  },
  {
    key: "11",
    image: "./Images/ConnectionsPMS/11.png",
    name: "frontmanager / SCsoft Skottke Computer",
    region: "Germany",
    certification: "Standard",
  },
  {
    key: "12",
    image: "./Images/ConnectionsPMS/12.png",
    name: "GastHoTec/Wachendorf GmbH",
    region: "Germany",
    certification: "Premium",
  },
  {
    key: "13",
    image: "./Images/ConnectionsPMS/13.png",
    name: "GMS Hotel Felix / GMS Zentrake Österrich",
    region: "Austria",
    certification: "Basic",
  },
  {
    key: "14",
    image: "./Images/ConnectionsPMS/14.png",
    name: "Hellmedia",
    region: "Germany",
    certification: "Premium",
  },
  {
    key: "15",
    image: "./Images/ConnectionsPMS/15.png",
    name: "Hope hotel PMS / Arne Madro EDV-Organisation GmbH",
    region: "Germany",
    certification: "Standard",
  },
  {
    key: "16",
    image: "./Images/ConnectionsPMS/16.png",
    name: "Host PMS",
    region: "Germany",
    certification: "Premium",
  },
  {
    key: "17",
    image: "./Images/ConnectionsPMS/17.png",
    name: "Hotcheck Win",
    region: "Germany",
    certification: "Premium",
  },
  {
    key: "18",
    image: "./Images/ConnectionsPMS/18.png",
    name: "Hotel.Profi / DATAreform GmbH",
    region: "Germany",
    certification: "Premium",
  },
  {
    key: "19",
    image: "./Images/ConnectionsPMS/19.png",
    name: "Hotelia PMS/Telicon",
    region: "Canada",
    certification: "Premium",
  },
  {
    key: "20",
    image: "./Images/ConnectionsPMS/20.png",
    name: "Hotelinco PMS / MAiS Information Systems Inc",
    region: "Slovenia",
    certification: "Standard",
  },
  {
    key: "21",
    image: "./Images/ConnectionsPMS/21.png",
    name: "HotelStar / MLR xNet GmbH",
    region: "Germany",
    certification: "Basic",
  },
  {
    key: "22",
    image: "./Images/ConnectionsPMS/22.png",
    name: "Hotline",
    region: "Germany",
    certification: "Premium",
  },
  {
    key: "23",
    image: "./Images/ConnectionsPMS/23.png",
    name: "HS/3® Hotelsoftware",
    region: "Germany",
    certification: "Premium",
  },
  {
    key: "24",
    image: "./Images/ConnectionsPMS/24.png",
    name: "Ibelsa",
    region: "Germany",
    certification: "Premium",
  },
  {
    key: "25",
    image: "./Images/ConnectionsPMS/25.png",
    name: "Infor",
    region: "United States",
    certification: "Premium",
  },
  {
    key: "26",
    image: "./Images/ConnectionsPMS/26.png",
    name: "Lodgetools INC. / InnTender PMS",
    region: "United States",
    certification: "Premium",
  },
  {
    key: "27",
    image: "./Images/ConnectionsPMS/27.png",
    name: "Lodgit Hotelsoftware GmbH",
    region: "Germany",
    certification: "Premium",
  },
  {
    key: "28",
    image: "./Images/ConnectionsPMS/28.png",
    name: "micros Fidelio / Oracle",
    region: "United States",
    certification: "Standard",
  },
  {
    key: "29",
    image: "./Images/ConnectionsPMS/29.png",
    name: "Oxygen Hotel Software / Vocator AG",
    region: "Switzerland",
    certification: "Standard",
  },
  {
    key: "30",
    image: "./Images/ConnectionsPMS/31.png",
    name: "Paxer",
    region: "Chile",
    certification: "Premium",
  },
  {
    key: "31",
    image: "./Images/ConnectionsPMS/32.png",
    name: "PentHotel",
    region: "Portugal",
    certification: "Premium",
  },
  {
    key: "32",
    image: "./Images/ConnectionsPMS/33.png",
    name: "PMS Autoupdate / Asron Hotels and Hostels Holdings AG",
    region: "Germany",
    certification: "Premium",
  },
  {
    key: "33",
    image: "./Images/ConnectionsPMS/34.png",
    name: "Protel",
    region: "Germany",
    certification: "Premium",
  },
  {
    key: "34",
    image: "./Images/ConnectionsPMS/35.png",
    name: "SIHOT.PMS / Gubse Akitiengesellschaft",
    region: "Germany",
    certification: "Standard",
  },
  {
    key: "35",
    image: "./Images/ConnectionsPMS/36.png",
    name: "Soferu® Hotelsoftware (PMS) // Swoppen® Systems",
    region: "Germany",
    certification: "Premium",
  },
  {
    key: "36",
    image: "./Images/ConnectionsPMS/37.png",
    name: "W & K Software",
    region: "Germany",
    certification: "Standard",
  },
  {
    key: "36",
    image: "./Images/ConnectionsPMS/38.png",
    name: "Webpmspro (WPP) / TDS TRAVEL DISTRIBUTION SOLUTIONS",
    region: "Germany",
    certification: "Standard",
  },
  {
    key: "37",
    image: "./Images/ConnectionsPMS/39.png",
    name: "WINHOTEL / CDSoft Vertiebs GmbH",
    region: "Germany",
    certification: "Premium",
  },
];

export const OTHER = [
  {
    key: "1",
    image: "./Images/ConnectionsOTHER/13.png",
    name: "Bookassist",
    region: "Germany, Austria, Switzerland",
    audience: "Hotel",
    solutions: "Booking Engine",
  },
  {
    key: "2",
    image: "./Images/ConnectionsOTHER/1.png",
    name: "caesar-data",
    region: "Germany, Austria",
    audience: "Hotel, Austria",
    solutions: "Booking Engine",
  },
  {
    key: "3",
    image: "./Images/ConnectionsOTHER/2.png",
    name: "CultBooking",
    region: "Worldwide",
    audience: "All Types",
    solutions: "Booking Engine",
  },
  {
    key: "4",
    image: "./Images/ConnectionsOTHER/3.png",
    name: "DS Destination Solutions",
    region: "Germany, Austria",
    audience: "Apartments, Vacation, Rentals, Small Hotels, Hotel",
    solutions: "Destination Management System (DMS)",
  },
  {
    key: "5",
    image: "./Images/ConnectionsOTHER/4.png",
    name: "Feratel",
    region: "Germany, Austria",
    audience: "Hotel",
    solutions: "Destination Management System (DMS)",
  },
  {
    key: "6",
    image: "./Images/ConnectionsOTHER/5.png",
    name: "HotelBeds",
    region: "Spain, International",
    audience: "Hotel",
    solutions: "Destination Management System (DMS)",
  },
  {
    key: "7",
    image: "./Images/ConnectionsOTHER/6.png",
    name: "HotelREZ",
    region: "Europe",
    audience: "Hotel",
    solutions: "Destination Management System (DMS)",
  },
  {
    key: "8",
    image: "./Images/ConnectionsOTHER/7.png",
    name: "Hotusa",
    region: "Spanien, International",
    audience: "Hotel",
    solutions: "Destination Management System (DMS)",
  },
  {
    key: "9",
    image: "./Images/ConnectionsOTHER/8.png",
    name: "reconline",
    region: "Europe",
    audience: "Hotel",
    solutions: "Distribution Partner",
  },
  {
    key: "10",
    image: "./Images/ConnectionsOTHER/9.png",
    name: "Rentals United",
    region: "Spanien, International",
    audience: "Apartments, Vacation, Rentals, Small Hotels",
    solutions: "Global Distribution Systems (GDS)",
  },
  {
    key: "11",
    image: "./Images/ConnectionsOTHER/11.png",
    name: "Tiscover",
    region: "Germany, Austria",
    audience: "All Types",
    solutions: "Wholesale",
  },
  {
    key: "12",
    image: "./Images/ConnectionsOTHER/12.png",
    name: "Tomas",
    region: "Germany, Austria",
    audience: "All Types",
    solutions: "Wholesale",
  },
];

export const ArticleData = [
  {
    key: "1",
    image: "./Images/Articles/article1.jpg",
    date: "08 September 2020",
    title:
      "TripGeni Users Dynamically Optimize Their Room Rates with RoomPriceGenie",
    subtitle:
      "TripGeni channel manager with new interface to automated revenue management solution",
    link:
      "/news-TripGeni-Users-Dynamically-Optimize-Their-Room-Rates-with-RoomPriceGenie",
    heading:
      "TripGeni channel manager with new interface to automated revenue management solution",
    subheading:
      "Berlin, 8 September 2020. Hotels generally react to fluctuations in demand by adjusting prices. In peak demand periods, they maximize their revenues, if demand is low, they optimize capacity utilization. These time-consuming price adjustments increase the competitive pressure on smaller supplier providers with fewer resources. With RoomPriceGenie, even smaller hotels and accommodations automatically adjust their room rates to the market situation, thereby optimizing their revenues while reducing manual effort. The adjusted prices can now be updated directly in the TripGeni channel manager.",
    paragraph: [
      "Small hotels and accommodations in particular are facing increasing difficulties: In view of fluctuating demand and growing competition from large hotels, they have to calculate their room rates carefully. If they are too cheap, they make no profit. If they are too expensive, they do not generate enough revenue. The way out of this dilemma is dynamic pricing that optimizes rates according to occupancy and market conditions.",
      "Large hotels use complex revenue management systems and dedicated staff to correctly price both peaks and lows in demand. However, many smaller hotels do not have sufficient resources to perform this complex task on a daily basis and often also lack the necessary market information. The optimum pricing for overnight stays depends on many factors: Capacity utilization, future booking trends (pickup) and the hotel’s own cost structure, but also the development of demand (seasonal periods, events) or competitive offers.",
      "The revenue management system RoomPriceGenie from the Swiss company RPG AG relieves smaller hotels, B&Bs and other accommodation providers of this work. It permanently analyzes thousands of market prices, booking trends and planned events in the area (for example trade fairs, congresses, sports events or concerts) and calculates the optimal room price per period. RoomPriceGenie also takes into account minimum and maximum prices as well as seasonal cycles. If desired, new prices are automatically updated several times a day directly in selected PMS or channel managers – and now also in TripGeni.",
      "The 2-month test phase with a pilot user is now completed. With immediate effect the new interface to RoomPriceGenie is available to every TripGeni user. “RoomPriceGenie is an effective way for our customers to be more competitive with dynamic pricing,” says Robert Reinsch, Head of Customer Care and Partner Management at TripGeni. “This proven solution helps especially smaller hotels to boost their revenues and reduces the effort for market observation and calculation”.",
    ],
  },
  {
    key: "2",
    image: "./Images/Articles/article2.jpg",
    date: "29 July 2020",
    title: "TripGeni Channel Manager with New Interface to Airbnb",
    subtitle:
      "TripGeni facilitates hotels, pensions and bed & breakfast businesses to use the world’s most popular accommodation platform.",
    link: "/news-TripGeni-Channel-Manager-with-New-Interface-to-Airbnb",
    heading:
      "TripGeni facilitates hotels, pensions and bed & breakfast businesses to use the world’s most popular accommodation platform.",
    subheading:
      "Berlin, 29 July 2020. TripGeni has implemented an interface to Airbnb. As the leading platform for authentic accommodation, there are more than seven million homes on Airbnb in more than 100,000 cities worldwide. TripGeni users can now benefit from the platform’s high popularity and loyal user community.",
    paragraph: [
      "Airbnb is one of the fastest-growing companies in the industry: founded in 2008 as “Airbed & Breakfast” in San Francisco, the platform generated ten million guest arrivals by 2012 – today this figure is over 750 million. At Google, Airbnb is the most searched-for accommodations brand. Airbnb’s original purpose was to provide home-sharing accommodation. But for many years now commercial providers have also been able to present themselves on Airbnb, provided they meet the required standards.",
      "Properties from professional providers should have a unique, independent environment and style, offer personal hospitality and must not belong to a mass-market hotel chain. For example, boutique hotels, aparthotels, pensions, bed & breakfasts, hostels and lodges are explicitly welcome.",
      "“I think Airbnb is a great complementary offer, especially for smaller houses, and can make a real difference, particularly in the Corona period”, says Robert Reinsch, Head of Customer Care and Partner Management at TripGeni. “With TripGeni, providers now benefit from the strong Airbnb brand and high visibility. For example, hotels can increase their short-term bookings by being listed on Airbnb. But the large network and loyal community are also an advantage. Almost 90 percent of guests who book a hotel room on Airbnb return to the platform for their next trip.”",
    ],
  },
  {
    key: "3",
    image: "./Images/Articles/article3.jpg",
    date: "28 May 2020",
    title: "TripGeni Supports the “Digital Hotel” Initiative",
    subtitle:
      "The Channel Manager is part of a large free aid package for hoteliers to overcome the Corona crisis",
    link: "/news-TripGeni-Supports-the-Digital-Hotel-Initiative",
    heading:
      "The Channel Manager is part of a large free aid package for hoteliers to overcome the Corona crisis",
    subheading:
      "Berlin, 28 May 2020. TripGeni, the service-oriented channel management provider for hotels, is involved in the “Digital Hotel” initiative. The initiative is a cooperation between various hotel technology companies, tourism service providers and associations. The aim is to help hotels take advantage of the opportunities offered by digitalization to better position themselves after the COVID-19 crisis. To this end, the initiative provides hotel operators with digitisation packages worth over EUR 10,000 free of charge for a period of six months.",
    paragraph: [
      "According to Fairmas – Specialist for management reporting for the hotel industry – 72% of hotels in Germany were closed in April, and sales across Germany were 96% down on the previous year. This makes the hospitality industry one of the sectors most affected by the COVID19 pandemic. And although the first steps have now been taken out of the lockdown, the return to normality will take a long time. The Federal Competence Centre for Tourism does not expect travel and business activities to return to normal until 2023. For most hotel operators, the long-term consequences are not yet foreseeable at all. Maybe the pandemic will have a lasting effect on travel behaviour and the expectations of guests.",
      "“In this situation, digitization and the further automation of central processes offer great opportunities,” explains Dr. Reinhard Vogel, CEO of TripGeni. “It is already becoming apparent that hotels relying on digitalization before the pandemic are better able to cope with the crisis – partly because they can implement optimizations more easily and recognize revenue potential more quickly.” To make it easier for all hotel operators to take the steps towards digitalization and automation, TripGeni is participating as a partner in the “Digital Hotel” initiative.",
      "Thanks to the initiative, hotel operators may select all necessary functions from a package of coordinated software products and test them free of charge for at least six months, from PMS, channel management and digital consultancy to competitive analysis and reputation management. TripGeni is offering the channel manager and the booking mask TripGeniBooking free of charge for six months. Initial setup and individual consulting are also included. This enables hoteliers to optimize their online sales and significantly increase the number of direct bookings via the hotel website.",
      "The TripGeni offer alone enables hotel operators to save more than 1,000 EUR. The total assistance package of all ten technology providers is worth over 10,000 EUR. In addition to TripGeni, this includes Apaleo, HQPlus, Customer Alliance, Code2order, Hotelshop.one, DialogShift, Überblick, Seatris.ai and Digital HC. Important: All tools will be implemented in such a way that they interlock seamlessly and thus significantly simplify the processes in the hotels as a whole. The HSMA supports the campaign.",
      "Interested hoteliers can register directly online via the initiative’s website (German).",
    ],
  },
  {
    key: "4",
    image: "./Images/Articles/article4.jpg",
    date: "21 April 2020",
    title:
      "TripGeni Is the Exclusive Channel Management Partner for Simply Hotel 2",
    subtitle:
      "New PMS from bonit.at comes in summer / High interface quality also for small and price sensitive accommodation providers",
    link:
      "/news-TripGeni-Is-the-Exclusive-Channel-Management-Partner-for-Simply-Hotel-2",
    heading:
      "New PMS from bonit.at comes in summer / High interface quality also for small and price sensitive accommodation providers",
    subheading:
      "Berlin, 21 April 2020. With excitement many hoteliers are waiting for the announced property management system (PMS) Simply Hotel 2 of the Austrian provider bonit.at Software. TripGeni was selected by bonit.at as exclusive partner for channel management. The new property management system (PMS) is scheduled for release in summer 2020 – the interface is finished already.",
    paragraph: [
      "For many years, bonit.at successfully served small hotels, room rentals and guesthouses up to 50 rooms with the inexpensive and easy to use room reservation program BONit Hotel Professionell & Web. After discontinuation of the product in 2018, it soon became clear that there is still a great demand in the market and especially in Germany for a simple, affordable PMS solution for small hotels. Therefore bonit.at developed a new professional hotel software based on BONit Hotel Professionell & Web for small and medium sized hotels and other accommodation providers.",
      "Simply Hotel 2 offers all the functions needed in everyday hotel life at a reasonable fixed price, without the usual restrictions on the number of rooms, categories, items, etc. This also includes the use of numerous online booking channels via the powerful channel management platform of TripGeni from Berlin. Users have two different solutions available to them, depending on their support needs.",
      "With the cost-effective web-based software MappingMaster, price-sensitive accommodation providers can activate and configure their desired booking portals largely autonomously. Nevertheless, they do not have to compromise on interface quality, because the solution uses the same proven technology that has made TripGeni a designated top partner of platforms such as Booking.com or Expedia. The lower support effort enables MappingMaster to offer its technology at a particularly attractive price. Those who want more support and value personal assistance can directly use the TripGeni Channel Manager.",
      "“Many of our existing hotel and restaurant customers are eagerly waiting for our brand new PMS Simply Hotel 2”, says Roland Berghöfer, managing director of bonit.at Software. “As with BONit Hotel Professionell & Web, we have again chosen TripGeni as our exclusive partner for channel management. Reasons for this are the customer-friendly pricing, the easy integration and the connection of all relevant online channels including booking possibilities on the hotels’ own website”.",
      "“We are proud that bonit.at has chosen us as its exclusive partner,” comments Dr. Reinhard Vogel, Managing Director of TripGeni. “This shows that our offers are not only convincing in terms of quality, but are also very competitive in the lower price segment”.",
      "The interface to TripGeni has already been completed. In May 2020, the beta test phase for Simply Hotel 2 will start; the PMS should be available in July.",
    ],
  },
  {
    key: "5",
    image: "./Images/Articles/article5.jpg",
    date: "10 March 2020",
    title: "TripGeni in 2020 Again Premier Partner of Booking.com",
    subtitle:
      "Top scores in interface quality, customer consulting and partner commitment",
    link: "/news-TripGeni-in-2020-Again-Premier-Partner-of-Booking.com",
    heading:
      "Top scores in interface quality, customer consulting and partner commitment",
    subheading:
      "Berlin, 10 March 2020. Every year Booking.com awards the “Premier Partner” status to its leading connectivity providers as part of its Connectivity Partner Programme. In 2020, channel management provider TripGeni is once again among the partners with the most advanced systems, the broadest range of products and services and the most reliable connections for hotels.",
    paragraph: [
      "Simply Hotel 2 offers all the functions needed in everyday hotel life at a reasonable fixed price, without the usual restrictions on the number of rooms, categories, items, etc. This also includes the use of numerous online booking channels via the powerful channel management platform of TripGeni from Berlin. Users have two different solutions available to them, depending on their support needs.",
      "The necessary technical connection of the hotel software (PMS) to the Booking.com platform is handled by so-called connectivity providers, primarily channel managers and PMS suppliers. The quality of their systems and their work determines how reliably the hotels are connected to Booking.com and which services and products of the platform they can use.",
      "In order to constantly optimise the quality of these connections, Booking.com has set up its Connectivity Partner Programme. The programme helps partners to identify weaknesses and to make the most of potential for improvement. Booking.com continuously evaluates interface performance, system reliability and cooperation quality and assigns points for the performance achieved by the partners. Depending on the total score received, partners are awarded “Standard”, “Advanced” or “Premier” status. As a “Premier Partner” Booking.com refers to its “top and highest value connectivity providers” with the best customer development, the most advanced systems, the widest range of interface features and the highest connection quality.",
      "“The fact that TripGeni was able to regain Premier status confirms our philosophy,” says Robert Reinsch, Head of Customer Care and Partner Management at TripGeni. “It is important to us to offer our customers not only first-class interfaces, but also outstanding personalized service. With our commission model, we are always very close to our customers and can offer much more than just standard support – right up to in-depth individual consultation.”",
    ],
  },
  {
    key: "6",
    image: "./Images/Articles/article6.jpg",
    date: "11 February 2020",
    title: "TripGeni Channel Manager Offers Interface to Bedandbreakfast.eu",
    subtitle:
      "The world’s largest bed & breakfast platform is attractive for small hotels, B&B innkeepers and holiday apartment providers",
    link:
      "/news-TripGeni-Channel-Manager-Offers-Interface-to-Bedandbreakfast.eu",
    heading:
      "The world’s largest bed & breakfast platform is attractive for small hotels, B&B innkeepers and holiday apartment providers",
    subheading:
      "Berlin, 11 February 2020. TripGeni is the first channel management provider from the German-speaking world to implement an interface to the world’s largest bed & breakfast platform, Bedandbreakfast.eu. Small hotel businesses, B&B innkeepers and providers of holiday homes and apartments thus get an attractive additional channel for online marketing of their rooms.",
    paragraph: [
      "The operator of the Bedandbreakfast.eu portal, Bedandbreakfast.eu B.V., was founded in 2009 and is based in Eindhoven in the Netherlands. The platform offers more than four million rooms in 224 countries and in all categories from simple to luxurious.",
      "The company defines “Bed and Breakfast” (B&B) as small-scale accommodation incl. breakfast for touristic, mostly short stays and usually located in a home or outbuilding and run by the owners themselves. At Bedandbreakfast.eu, establishments with up to 15 rooms can register and present their offers on the website. Interested users can easily and conveniently search for B&Bs, compare them and make reservations.",
      "Bedandbreakfast.eu sees itself as an online advertising and marketing channel for B&B providers. The website is search engine optimized, places ads on Google and is visited by millions of people every year. The platform’s online marketing includes blog, newsletter and social media. In addition, B&B innkeepers are also provided with tips via blog and newsletter.",
      "As of now, users of the channel manager TripGeni can offer their rooms directly via Bedandbreakfast.eu. Small and medium-sized hotel and B&B operators in particular can thus expand their marketing options. Compared to indirect placement, where Bedandbreakfast.eu acts as an affiliate for other OTAs, directly connected businesses benefit from better ranking, lower commission and control over the content displayed.",
      "Hosts who agree to release their offers for instant booking are listed free of charge. Bedandbreakfast.eu handles the reservation and payment processes and charges a commission of 10 percent plus VAT per reservation. For protection in case of cancellations and no-shows, three different cancellation policies are offered.",
      "Those who prefer reservation requests, i. e. who only want to present themselves on the platform, pay an annual fee of 180 euros plus VAT, independent of turnover. In this case the providers themselves take care of reservations and payments. If needed, they can make use of the administration and communication tools provided by Bedandbreakfast.eu.",
    ],
  },
];
